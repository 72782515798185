import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../../components/headerFooterLayout"

export default ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      cricketHill: file(relativePath: { regex: "/cricket-hill.jpg/" }) {
        childImageSharp {
          sizes(maxWidth: 1240) {
            ...GatsbyImageSharpSizes
          }
        }
      }
    }
  `)

  return (
    <Layout style={{ margin: 0, padding: 0 }} currentPath={location.pathname}>
      <Img
        title="Hero Image"
        alt="Rich Soni playing at Cricket Hill"
        sizes={data.cricketHill.childImageSharp.sizes}
      />
    </Layout>
  )
}
